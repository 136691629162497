jQuery(document).ready(function ($) {
    var $slide_content = $('.home-slide .slide-content .swiper-container');
    var $slide_image = $('.home-slide .slide-image .swiper-container');

    if (!$slide_content.length) return

    var slide_contents = new Swiper($slide_content, {
        allowTouchMove: false,
        spaceBetween: 50,
        slidesPerView: 1,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });

    var slide_images = new Swiper($slide_image, {
        slidesPerView: 1,
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        navigation: {
            prevEl: $slide_image.closest('.inner').find('.slide-navigation.prev'),
            nextEl: $slide_image.closest('.inner').find('.slide-navigation.next'),
        },
        thumbs: {
            swiper: slide_contents,
        },
    });
});