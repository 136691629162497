jQuery(document).ready(function ($) {
    var $partners = $('.home-partners .swiper-container');

    if (!$partners.length) return

    var mySwiper = new Swiper($partners, {
        centerInsufficientSlides: true,
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 25,
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        navigation: {
            prevEl: $partners.parent().find('.carousel-navigation.prev'),
            nextEl: $partners.parent().find('.carousel-navigation.next'),
        },
        breakpoints: {
            1199: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 15,
            },
            991: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 15,
            },
            575: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 10,
            }
        }
    });
});