jQuery(document).ready(function ($) {
    var $btnTogleCategories = $('.btn-toggle-categories');

    if (!$btnTogleCategories.length) return

    var $toggleCategories = $('.toggle-categories');

    $btnTogleCategories.on('click', function (e) {

        e.preventDefault();

        $toggleCategories.slideToggle();
        $toggleCategories.addClass('mobile');
    });
});